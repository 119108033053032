
import useCohorts from '@/composables/useCohorts';
import { Cohort, EnumStringMapping } from '@/types/Cohort';
import { TableCohort } from '@/types/TableCohort';
import { defineComponent, computed, ref, watch, Ref } from 'vue';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Explorer',
  components: {},
  setup() {
    const router = useRouter();
    const {
      cohorts,
      COHORT_TYPES,
      COHORT_TYPES_2_NO_EMPTY,
      STUDY_TYPES,
      PARTICIPANT_TYPES,
      INDICATION_FIELDS,
      BIOPROBES_AVAILABLE,
      getRoute,
      toRecruitmentPeriodString,
    } = useCohorts();

    const onlyUnique = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    };

    const STUDY_TYPES_INCL_CUSTOM = computed(() => {
      let study_types = STUDY_TYPES.flatMap((e) => e.name)
        .concat(
          cohorts.value
            .flatMap((cohort) => cohort.characteristics.studyTypeOther)
            .filter(onlyUnique)
        )
        .filter((x) => x !== undefined)
        .filter((x) => x !== null)
        .filter((x) => x !== '')
        .filter((x) => x !== ' ');
      return study_types;
    });

    const INDICATION_FIELDS_INCL_CUSTOM = computed(() => {
      let indicationFields = INDICATION_FIELDS.flatMap((e) => e.name)
        .concat(
          cohorts.value
            .flatMap((cohort) => cohort.characteristics.indicationOther)
            .filter(onlyUnique)
        )
        .filter((x) => x !== undefined)
        .filter((x) => x !== null)
        .filter((x) => x !== '')
        .filter((x) => x !== ' ');
      return indicationFields;
    });

    // custom filters
    const applyFilter = (
      selectedOptions: Ref,
      targetFilter: any,
      filterMatchMode: any
    ) => {
      if (!selectedOptions.value || selectedOptions.value.length <= 0) {
        targetFilter.constraints = [
          { value: null, matchMode: filterMatchMode },
        ];
      } else {
        targetFilter.constraints = selectedOptions.value.flatMap(
          (elem: EnumStringMapping) => {
            return {
              value: elem.name,
              matchMode: filterMatchMode,
            };
          }
        );
      }
    };

    const selectedCohortType2 = ref();
    watch(selectedCohortType2, () => {
      if (!selectedCohortType2.value || selectedCohortType2.value.length <= 0) {
        filters.value.cohortType2.constraints = [
          { value: null, matchMode: FilterMatchMode.EQUALS },
        ];
      } else {
        console.log(selectedCohortType2.value);
        filters.value.cohortType2.constraints = selectedCohortType2.value.map(
          (elem: any) => {
            return {
              value: elem.name,
              matchMode: FilterMatchMode.EQUALS,
            };
          }
        );
      }
    });

    const selectedStudyType = ref();
    watch(selectedStudyType, () => {
      if (!selectedStudyType.value || selectedStudyType.value.length <= 0) {
        filters.value.studyType.constraints = [
          { value: null, matchMode: FilterMatchMode.EQUALS },
        ];
      } else {
        filters.value.studyType.constraints = selectedStudyType.value.map(
          (elem: string) => {
            return {
              value: elem,
              matchMode: FilterMatchMode.EQUALS,
            };
          }
        );
      }
    });

    const selectedParticipantTypes = ref();
    watch(selectedParticipantTypes, () => {
      applyFilter(
        selectedParticipantTypes,
        filters.value.participantTypes,
        FilterMatchMode.CONTAINS
      );
    });

    const selectedIndicationFields = ref();
    watch(selectedIndicationFields, () => {
      if (
        !selectedIndicationFields.value ||
        selectedIndicationFields.value.length <= 0
      ) {
        filters.value.indicationFields.constraints = [
          { value: null, matchMode: FilterMatchMode.CONTAINS },
        ];
      } else {
        filters.value.indicationFields.constraints =
          selectedIndicationFields.value.flatMap((elem: string) => {
            return {
              value: elem,
              matchMode: FilterMatchMode.CONTAINS,
            };
          });
      }
    });

    const selectedBioprobesAvailable = ref();
    watch(selectedBioprobesAvailable, () => {
      filters.value.bioprobesAvailable.constraints = [
        {
          value: selectedBioprobesAvailable.value?.name,
          matchMode: FilterMatchMode.CONTAINS,
        },
      ];
    });

    const transformToTableCohorts = (cohorts: Cohort[]) => {
      let tableCohorts: TableCohort[] = [];
      cohorts.forEach((cohort) => {
        // participant types
        let participantTypesString = '';
        participantTypesString = cohort.characteristics.participantTypes
          ?.flatMap(
            (type) => PARTICIPANT_TYPES.find((x) => x.value === type)?.name
          )
          .join(' or ');

        // indication fields
        let indicationFieldsString = '';
        indicationFieldsString = cohort.characteristics.indication
          ?.flatMap(
            (indication) =>
              INDICATION_FIELDS.find((e) => e.value === indication)?.name
          )
          .join(', ');
        if (
          cohort.characteristics.indicationOther &&
          cohort.characteristics.indicationOther.length > 0
        ) {
          indicationFieldsString =
            indicationFieldsString +
            ', ' +
            cohort.characteristics.indicationOther.join(', ');
        }

        // study type
        let studyTypeString = '';
        if (cohort.characteristics.studyTypeOther) {
          studyTypeString = cohort.characteristics.studyTypeOther;
        } else {
          studyTypeString =
            STUDY_TYPES.find(
              (x) => x.value === cohort.characteristics.studyType
            )?.name + '';
        }

        // recruitment period
        const recruitmentPeriod = toRecruitmentPeriodString(
          cohort.recruitment.start,
          cohort.recruitment.end
        );

        let tableCohort: TableCohort = {
          route: getRoute(cohort),
          name: cohort.acronym
            ? cohort.acronym + ': ' + cohort.name
            : cohort.name,
          objectives: cohort.studyObjectives,
          cohortType2: cohort.characteristics.cohortType2
            ? COHORT_TYPES_2_NO_EMPTY.find(
                (x) => x.value === cohort.characteristics.cohortType2
              )?.name + ''
            : '',
          studyType: studyTypeString,
          participantTypes: participantTypesString,
          indicationFields: indicationFieldsString,
          recruitmentPeriod: recruitmentPeriod,
          sampleSize: cohort.recruitment.estimatedCurrentSize,
          bioprobesAvailable: cohort.bioprobes.available ? 'yes' : 'no',
        };
        tableCohorts.push(tableCohort);
      });
      return tableCohorts;
    };

    const tableCohorts = computed(() => transformToTableCohorts(cohorts.value));

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      objectives: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      cohortType2: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      studyType: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      participantTypes: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      indicationFields: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      bioprobesAvailable: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    const clearGlobalFilter = () => {
      filters.value.global = {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      };
    };

    const openDetailsView = (event: any) => {
      router.push({
        name: 'Cohort Detail Page',
        params: { name: event.data?.route },
      });
    };

    return {
      cohorts,
      tableCohorts,
      filters,
      clearGlobalFilter,
      openDetailsView,
      selectedCohortType2,
      COHORT_TYPES,
      COHORT_TYPES_2_NO_EMPTY,
      selectedStudyType,
      STUDY_TYPES_INCL_CUSTOM,
      selectedParticipantTypes,
      PARTICIPANT_TYPES,
      selectedIndicationFields,
      INDICATION_FIELDS_INCL_CUSTOM,
      selectedBioprobesAvailable,
      BIOPROBES_AVAILABLE,
    };
  },
});
