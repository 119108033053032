export interface Cohort {
  acronym: string;
  name: string;
  studyObjectives: string;
  description: string;
  projectUrl: string;
  contacts: Contact[];
  characteristics: Characteristics;
  recruitment: Recruitment;
  coreVariables: CoreVariable[];
  coreVariablesFurtherInfos?: string;
  bioprobes: Bioprobes;
}

export interface Contact {
  name: string;
  department: string;
  institution: string;
  mail: string;
}

export enum CohortType {
  POPULATION_BASED = 'POPULATION_BASED',
  PATIENT_BASED = 'PATIENT_BASED',
}

export enum CohortType2 {
  INVENTORY_COHORT = 'INVENTORY_COHORT',
  REFERENCE_COHORT = 'REFERENCE_COHORT',
}

export enum StudyType {
  CROSS_SECTIONAL = 'CROSS_SECTIONAL',
  COHORT = 'COHORT',
  PROSPECTIVE_COHORT = 'PROSPECTIVE_COHORT',
  SEROPREVALENCE = 'SEROPREVALENCE',
}

export enum ParticipantType {
  CONDITION_DIAGNOSED = 'CONDITION_DIAGNOSED',
  NO_DIAGNOSIS = 'NO_DIAGNOSIS',
  CONTROL_GROUP = 'CONTROL_GROUP',
}

export enum IndicationField {
  COVID = 'COVID',
  PNEUMONIA = 'PNEUMONIA',
  SEPSIS = 'SEPSIS',
  CARDIOVASCULAR = 'CARDIOVASCULAR',
  CANCER = 'CANCER',
  NEUROLOGICAL = 'NEUROLOGICAL',
  RESPIRATORY_DISEASE_DISORDER = 'RESPIRATORY_DISEASE_DISORDER',
}

export interface Characteristics {
  cohortType: CohortType;
  cohortType2: CohortType2;
  hasCovid: boolean;
  indication: IndicationField[]; // if !covidOnly
  indicationOther: string[]; // if !covidOnly
  studyType: StudyType;
  studyTypeOther: string;
  participantTypes: ParticipantType[];
}

export enum GenderType {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  DIVERS = 'DIVERS',
}

export interface Recruitment {
  inclusionCriteria: string;
  exclusionCriteria: string;
  start: Date;
  end: Date;
  estimatedCurrentSize: number;
  ageAtRecruitement: string;
  gender: GenderType[];
}

export interface CoreVariable {
  name: string;
  details: string;
}

export interface Bioprobes {
  available: boolean;
  sampleLocatorUrl: string; // if(available)
  sampleTypes: SampleTypes; // if(available)
}

export enum PrimaryBloodTubes {
  MONOVETTE_SERUM = 'MONOVETTE_SERUM',
  CITRATE_MONOVETTE_PLASMA = 'CITRATE_MONOVETTE_PLASMA',
  HEPARIN_MONOVETTE_PLASMA_PBMC = 'HEPARIN_MONOVETTE_PLASMA_PBMC',
  CPT_PBMC = 'CPT_PBMC',
  EDTA_MONOVETTE_PLASMA = 'EDTA_MONOVETTE_PLASMA',
  EDTA_MONOVETTE_PLASMA_PBMC = 'EDTA_MONOVETTE_PLASMA_PBMC',
  EDTA_MONOVETTE_PLASMA_CELLS_BUFFY_COAT = 'EDTA_MONOVETTE_PLASMA_CELLS_BUFFY_COAT',
  EDTA_WHOLE_BLOOD = 'EDTA_WHOLE_BLOOD',
  RNA_BLOOD_PAXGENE = 'RNA_BLOOD_PAXGENE',
  RNA_ISOLATED = 'RNA_ISOLOATED',
  LIHEP = 'LIHEP',
}

export enum UrineComponents {
  SUPERNATANT = 'SUPERNATANT',
  SEDIMENT = 'SEDIMENT',
}

export enum SmearTypes {
  NASPHARYNGEAL_SWAB = 'NASPHARYNGEAL_SWAB',
  OROPHARYNGEAL_SWAB = 'OROPHARYNGEAL_SWAB',
  SALIVA = 'SALIVA',
  BROCHOALVEOLAR_LAVAGE_BAL = 'BROCHOALVEOLAR_LAVAGE_BAL',
  TRACHEAL_SECRETION_ENTA = 'TRACHEAL_SECRETION_ENTA',
}

export interface SampleTypes {
  primaryBloodTubes: PrimaryBloodTubes[];
  primaryBloodTubesOther: string[];
  urineComponents: UrineComponents[];
  urineComponentsOther: string[];
  smearTypes: SmearTypes[];
  smearTypesOther: string[];
}

export interface EnumStringMapping {
  name: string;
  value: string;
}
