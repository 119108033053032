import { Cohort } from './../types/Cohort';
import { createStore } from 'vuex';
import cohorts from '@/assets/data/cohorts.json';

interface GlobalState {
  cohorts: Cohort[];
}

const state: GlobalState = {
  cohorts: [],
};

const mutations = {
  setCohorts(state: GlobalState, payload: Cohort[]) {
    state.cohorts = payload;
  },
};

export default createStore({
  state,
  mutations,
  // getters,
  actions: {
    async getCohorts({ commit }) {
      commit('setCohorts', cohorts);
    },
  },
  modules: {},
});
