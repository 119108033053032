
import { defineComponent, onMounted } from 'vue';
import IkebCredits from '@/components/IkebCredits.vue';
import NapkonFooter from '@/components/NapkonFooter.vue';
import useCohorts from '@/composables/useCohorts';

export default defineComponent({
  name: 'App',
  components: { IkebCredits, NapkonFooter },
  setup() {
    const { getCohorts } = useCohorts();
    onMounted(getCohorts);
  },
});
