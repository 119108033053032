import {
  CohortType,
  CohortType2,
  GenderType,
  IndicationField,
  ParticipantType,
  PrimaryBloodTubes,
  SmearTypes,
  StudyType,
  UrineComponents,
} from './../types/Cohort';
import { Cohort } from '@/types/Cohort';
import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

export default function useCohorts() {
  const store = useStore();
  const cohorts: ComputedRef<Cohort[]> = computed(
    (): Cohort[] => store.state.cohorts
  );
  const getCohorts = () => store.dispatch('getCohorts');

  // find cohort with matching short name
  const getCohortByRouteName = (name: string) => {
    return cohorts.value.find((x: Cohort) => getRoute(x) === name);
  };

  const COHORT_TYPES = [
    { name: ' ', value: null },
    { name: 'population based', value: CohortType.POPULATION_BASED },
    { name: 'patient based', value: CohortType.PATIENT_BASED },
  ];

  const COHORT_TYPES_2 = [
    { name: ' ', value: null },
    {
      name: 'inventory cohort (Covid-19)',
      value: CohortType2.INVENTORY_COHORT,
    },
    { name: 'reference cohort', value: CohortType2.REFERENCE_COHORT },
  ];

  const COHORT_TYPES_2_NO_EMPTY = [
    {
      name: 'inventory cohort (Covid-19)',
      value: CohortType2.INVENTORY_COHORT,
    },
    { name: 'reference cohort', value: CohortType2.REFERENCE_COHORT },
  ];

  const STUDY_TYPES = [
    { name: ' ', value: null },
    { name: 'cohort study', value: StudyType.COHORT },
    { name: 'prospective cohort study', value: StudyType.PROSPECTIVE_COHORT },
    { name: 'cross sectional study', value: StudyType.CROSS_SECTIONAL },
    { name: 'seroprevalence study', value: StudyType.SEROPREVALENCE },
  ];

  const INDICATION_FIELDS = [
    { name: 'COVID-19', value: IndicationField.COVID },
    { name: 'pneumonia', value: IndicationField.PNEUMONIA },
    { name: 'sepsis', value: IndicationField.SEPSIS },
    { name: 'cardiovascular', value: IndicationField.CARDIOVASCULAR },
    { name: 'cancer', value: IndicationField.CANCER },
    { name: 'neurological', value: IndicationField.NEUROLOGICAL },
    {
      name: 'respiratory disease/disorder',
      value: IndicationField.RESPIRATORY_DISEASE_DISORDER,
    },
  ];

  const PARTICIPANT_TYPES = [
    { name: 'condition diagnosed', value: ParticipantType.CONDITION_DIAGNOSED },
    { name: 'no diagnosis', value: ParticipantType.NO_DIAGNOSIS },
    { name: 'control group', value: ParticipantType.CONTROL_GROUP },
  ];

  const GENDER_TYPES = [
    { name: 'Female', value: GenderType.FEMALE },
    { name: 'Male', value: GenderType.MALE },
    { name: 'Diverse', value: GenderType.DIVERS },
  ];

  const BIOPROBES_AVAILABLE = [
    { name: 'yes', value: true },
    { name: 'no', value: false },
  ];

  const PRIMARY_BLOOD_TUBE_TYPES = [
    {
      name: 'Citrate Monovette (Plasma)',
      value: PrimaryBloodTubes.CITRATE_MONOVETTE_PLASMA,
    },
    {
      name: 'Heparin Monovette (Plasma + PBMC)',
      value: PrimaryBloodTubes.HEPARIN_MONOVETTE_PLASMA_PBMC,
    },
    { name: 'CPT (PBMC)', value: PrimaryBloodTubes.CPT_PBMC },
    {
      name: 'EDTA Monovette (Plasma)',
      value: PrimaryBloodTubes.EDTA_MONOVETTE_PLASMA,
    },
    {
      name: 'EDTA Monovette (Plasma + PBMC)',
      value: PrimaryBloodTubes.EDTA_MONOVETTE_PLASMA_PBMC,
    },
    {
      name: 'EDTA Monovette Plasma Cells (Buffy Coat)',
      value: PrimaryBloodTubes.EDTA_MONOVETTE_PLASMA_CELLS_BUFFY_COAT,
    },
    {
      name: 'EDTA Whole Blood',
      value: PrimaryBloodTubes.EDTA_WHOLE_BLOOD,
    },
    { name: 'Monovette Serum', value: PrimaryBloodTubes.MONOVETTE_SERUM },
    { name: 'RNA Blood (PAXgene)', value: PrimaryBloodTubes.RNA_BLOOD_PAXGENE },
    { name: 'Isolated RNA', value: PrimaryBloodTubes.RNA_ISOLATED },
    { name: 'LiHep', value: PrimaryBloodTubes.LIHEP },
  ];

  const URINE_COMPONENT_TYPES = [
    {
      name: 'Sediment',
      value: UrineComponents.SEDIMENT,
    },
    {
      name: 'Supernatant',
      value: UrineComponents.SUPERNATANT,
    },
  ];

  const SMEAR_TYPES = [
    {
      name: 'Bronchoalveolar Lavage (BAL)',
      value: SmearTypes.BROCHOALVEOLAR_LAVAGE_BAL,
    },
    {
      name: 'Naspharyngeal Swab',
      value: SmearTypes.NASPHARYNGEAL_SWAB,
    },
    {
      name: 'Oropharyngeal Swab',
      value: SmearTypes.OROPHARYNGEAL_SWAB,
    },
    {
      name: 'Saliva',
      value: SmearTypes.SALIVA,
    },
    {
      name: 'Tracheal Secretion (ENTA)',
      value: SmearTypes.TRACHEAL_SECRETION_ENTA,
    },
  ];

  /**
   * Computes the unique route to this cohort based on short & long name
   * @param cohort A cohort
   * @returns The route to this cohort details page
   */
  const getRoute = (cohort: Cohort) => {
    return (cohort.acronym ? cohort.acronym + '-' + cohort.name : cohort.name)
      .toLowerCase()
      .replaceAll(' ', '-');
  };

  /**
   * Returns the recruitment period in a string format
   * @param start start date
   * @param end end date
   * @return recruitment period string
   */
  const toRecruitmentPeriodString = (
    start: Date | undefined,
    end: Date | undefined
  ) => {
    if (start) {
      start = new Date(start);
    }
    if (end) {
      end = new Date(end);
    }

    let recruitmentPeriod: string;
    if (start && end) {
      recruitmentPeriod =
        start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
    } else if (start) {
      recruitmentPeriod = start.toLocaleDateString() + ' - open end';
    } else if (end) {
      recruitmentPeriod = 'unknown start - ' + end.toLocaleDateString();
    } else {
      recruitmentPeriod = 'unknown';
    }
    return recruitmentPeriod;
  };

  return {
    cohorts,
    getCohorts,
    getCohortByRouteName,
    COHORT_TYPES,
    COHORT_TYPES_2,
    COHORT_TYPES_2_NO_EMPTY,
    STUDY_TYPES,
    INDICATION_FIELDS,
    PARTICIPANT_TYPES,
    GENDER_TYPES,
    PRIMARY_BLOOD_TUBE_TYPES,
    URINE_COMPONENT_TYPES,
    SMEAR_TYPES,
    BIOPROBES_AVAILABLE,
    getRoute,
    toRecruitmentPeriodString,
  };
}
