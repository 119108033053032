import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// UI component library
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'primeflex/primeflex.css'; // grid system
import '@/assets/theme.css'; //theme

// Import UI components here
import Divider from 'primevue/divider';
import Chip from 'primevue/chip';
import Button from 'primevue/button';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import ToastService from 'primevue/toastservice';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dropdown from 'primevue/dropdown';

const app = createApp(App);
app.use(store).use(router).use(PrimeVue);
app.use(ToastService);

// Register UI components here
app.component('Divider', Divider);
app.component('Chip', Chip);
app.component('Button', Button);
app.component('Card', Card);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Fieldset', Fieldset);
app.component('Panel', Panel);
app.component('InputText', InputText);
app.component('MultiSelect', MultiSelect);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Dropdown', Dropdown);

app.mount('#app');
