import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from 'vue-router';
import Explorer from '../views/Explorer.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'NAPKON Cohort Explorer',
    component: Explorer,
  },
  {
    path: '/cohort/:name',
    name: 'Cohort Detail Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
  },
  {
    path: '/generator',
    name: 'Cohort Generator',
    component: () => import('../views/Generator.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
